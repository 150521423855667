<template>
    
    <!-- Sign In Body -->
    <div class="main-body">
        <div class="auth-body-wrapper">
            <div class="auth-body center">
                <p class="auth-title">Forgot your Account</p>
                <p class="auth-description">Enter your e-mail address below. We'll send you an e-mail with a link to reset your password.</p>
                <p class="auth-description">Don't have an account? <span class="auth-text-btn"> <a href="/sign-up"> Sign Up today</a></span> </p>

                <div class="auth-input-container">
                    <div class="auth-input-box">
                        <p class="input-label">Email address</p>
                        <input class="input-box" type="email" name="email" v-model="email">
                    </div>
                    <div class="btn sign-in-btn" @click="sendPasswordResetEmail()">
                        <p v-if="isSubmitting" style="height: 27px;position: relative;"><svg class="center" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px" height="28px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style="/* margin-top: 20px; */background: none;shape-rendering: auto;position: relative;width: 100%;text-align: center;height: 29px;/* top: 4px; */"><circle cx="50" cy="50" fill="none" stroke="#fff" stroke-width="8" r="35" stroke-dasharray="164.93361431346415 56.97787143782138" transform="rotate(274.643 50 50)"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform></circle></svg></p>
                        <p v-if="!isSubmitting" class="sign-in-text">Send Password Reset Email</p>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { sendPasswordResetEmail } from '@/apis/authentication'

export default {
    name: 'SignIn',
    data(){
        return{
            email: undefined,
            isSubmitting: false
        }
    },
    methods:{
        // Send password reset email
        async sendPasswordResetEmail(){
            this.isSubmitting = true;

            var emailStatus = await sendPasswordResetEmail(this.email);
            if(emailStatus=="success"){
                this.$root.$refs['snackbar'].showToast("Password Reset Email Sent", "#33a854");
            }
            else{
                var errorCode = emailStatus.code;
                if(errorCode == "auth/user-not-found"){
                    this.$root.$refs['snackbar'].showToast("User not found", "#e52627");
                }
                else{
                    this.$root.$refs['snackbar'].showToast("Something went wrong", "#e52627");
                }
            }
            this.isSubmitting = false;
        },
    }
}
</script>

<style scoped>
    .main-body{max-width: 1280px;margin: auto;margin-bottom: 48px;}
    .auth-body-wrapper{position: relative;padding: 0 12px;width: 100%;min-height: calc(100vh - 105px);}
    .auth-title{font-size: 20px;font-weight: 700;padding: 4px 0;display: inline-block;line-height: 24px;margin-bottom: 8px;}
    .auth-description{font-size: 15px;line-height: 1.4;font-weight: 400;color: #868686;max-width: 390px;}
    .auth-text-btn{margin: 0px;margin-left: 4px;line-height: 24px;font-size: 14px;font-weight: 600;color: #3e50b5;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;padding: 4px 0;cursor: pointer;}

    .auth-input-container{margin: 16px 0px;}
    .auth-input-box{margin-bottom: 4px;}
    .auth-input-box input-box{padding: 9px 12px;}
    .input-label{padding: 8px 4px;padding-bottom: 4px;font-size: 14px;font-weight: 500;}
    .sign-in-btn{margin: 0px;min-width: 120px;width: 100%;margin-top: 12px;padding: 7px 16px;line-height: 27px;}
    .sign-in-text{font-size: 15px;}

    /* Tablet */
    @media only screen and (max-width: 1024px){
        .main-body{grid-template-columns: 1fr;margin: 0;}
        .auth-body-wrapper{margin-left: unset;max-width: unset;min-height: 400px;}
        .auth-body{margin: 0;position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);}
    }

    /* Phone */
    @media only screen and (max-width: 600px){
        .auth-body{width: 90%;}
        .auth-body-wrapper{height: calc(100vh - 105px);}
    }
</style>